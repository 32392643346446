import styled, { keyframes } from "styled-components";

const littleButtonSwing = keyframes`
  0% {
    transform: translateX(-100%)
  }
  20% {
    transform: translateX(-80%);
  }
  40% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-100%);
  }
}`;
const buttonPulseAnimationWhite = keyframes`
  0% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.5;
    box-shadow: 0 0 0 5px #fff, 0 0 10px 10px #fff, 0 0 0 10px #fff;
  }
  100% {
    box-shadow: 0 0 0 5px #fff, 0 0 10px 10px #fff, 0 0 0 10px #fff;
    transform: scaleY(1.05);
    opacity: 0;
  }
}`;
const buttonPulseAnimationBlack = keyframes`
0% {
  opacity: 0.3;
}
40% {
  opacity: 0.5;
  box-shadow: 0 0 0 5px var(--text-color), 0 0 10px 10px var(--text-color), 0 0 0 10px var(--text-color);
}
100% {
  box-shadow: 0 0 0 5px var(--text-color), 0 0 10px 10px var(--text-color), 0 0 0 10px var(--text-color);
  transform: scaleY(1.05);
  opacity: 0;
}
}`;
const buttonPulseAnimationBlue = keyframes`
0% {
  opacity: 0.3;
}
40% {
  opacity: 0.5;
  box-shadow: 0 0 0 5px #00e1ff, 0 0 10px 10px #00e1ff, 0 0 0 10px #00e1ff;
}
100% {
  box-shadow: 0 0 0 5px #00e1ff, 0 0 10px 10px #00e1ff, 0 0 0 10px #00e1ff;
  transform: scaleY(1.05);
  opacity: 0;
}
}`;

const buttonColorPicker = (color) => {
  switch (color) {
    case "black":
      return "var(--text-color)";
    case "white":
      return "#fff";
    case "blue":
      return "#00e1ff";
    default:
      return "var(--text-color)";
  }
};

const buttonTextColorPicker = (color) => {
  switch (color) {
    case "black":
      return "#fff";
    case "white":
      return "var(--text-color)";
    case "blue":
      return "#fff";
    default:
      return "#fff";
  }
};
const buttonAnimationPicker = (color) => {
  switch (color) {
    case "black":
      return buttonPulseAnimationBlack;
    case "white":
      return buttonPulseAnimationWhite;
    case "blue":
      return buttonPulseAnimationBlue;
    default:
      return buttonPulseAnimationBlack;
  }
};

const Button = styled.button`
  color: ${(props) => buttonTextColorPicker(props.data_color)};
  background: ${(props) => buttonColorPicker(props.data_color)};
  border: 1px solid ${(props) => buttonColorPicker(props.data_color)};
  padding: 0px 35px;
  padding-top: ${(props) => (props.dataIsOSX === true ? "6px" : "unset")};
  text-decoration: none;
  font-family: CamptonMedium;
  cursor: pointer;
  border-radius: 30px;
  height: 60px;
  align-items: center;
  display: flex;
  font-size: 18px;
  width: max-content;
  ${(props) => (props.data_arrow ? "padding-right: 47px" : "")};
  position: relative;
  transition: all 0.2s ease-in-out;
  &:disabled {
    background: #fafafa;
    border: unset;
    &::before {
      animation: none;
    }
    color: #d8d8d8;
    &:hover {
      color: #d8d8d8;
      cursor: not-allowed;
      background: #fafafa;
      border: unset;
      box-shadow: none;
      transform: scale(1);
    }
  }
  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    top: 0;
    left: 0;
    animation: ${(props) => buttonAnimationPicker(props.data_color)} 2.3s
      ease-in-out 1.2s infinite;
  }

  &:after {
    content: "";
    background-image: url(${(props) =>
      props.data_arrow ? "/global/Arrow-right-2.svg" : ""});
    background-size: 18px 16px;
    background-repeat: no-repeat;
    position: absolute;
    right: 15px;
    width: 21px;
    margin-top: ${(props) => (props.dataIsOSX === true ? "0px" : "3px")};
    height: 18px;
  }
  &:hover {
    color: ${(props) => (props.data_color === "white" ? "#fff" : "#fff")};
    background: ${(props) => buttonColorPicker(props.data_color)};
    border: 1px solid
      ${(props) => (props.data_color === "white" ? "#31A1FF" : "#31A1FF")};
    &::before {
      animation: none;
    }
    &:after {
      filter: brightness(0) invert(1);
    }
    transform: scale(1.05);
    box-shadow: 0px 9px 23px -8px rgba(0, 137, 255, 0.6);
  }
  @media only screen and (min-width: 615px) {
    padding: 0px 50px;
    padding-top: ${(props) => (props.dataIsOSX === true ? "1px" : "0")};

    height: 60px;
    font-size: 1.071875em;
    /* padding-top:11px; */
    ${(props) => (props.data_arrow ? "padding-right: 57px" : "")};
  }
  @media only screen and (min-width: 615px) and (max-width: 1023px) {
    font-size: 26px;
    height: 60px;
    padding: 0px 70px;
    border-radius: 40px;
    &::before {
      border-radius: 40px;
    }
    &:after {
      content: "";
      background-image: url(${(props) =>
        props.data_arrow ? "/global/Arrow-right-2.svg" : ""});
      background-size: 29px 22px;
      background-repeat: no-repeat;
      position: absolute;
      right: 18px;

      width: 32px;
      margin-top: ${(props) => (props.data_mobile === true ? "0px" : "5px")};
      height: 22px;
    }
  }
`;

export default Button;

export const ButtonOutline = styled(Button)`
  background: transparent;
  overflow: hidden;
  position: relative;
  span {
    z-index: 1;
  }
  &:after {
    transform: translateX(-100%);
    animation: ${littleButtonSwing} 6s ease-in-out 2s infinite;
    transition: all 0.3s ease-in-out;
    z-index: 0;
    /* background: red !important; */
    background: ${(props) => buttonColorPicker(props.data_color)};
    width: 100%;
    height: 100%;
    margin: 0;
    border: 1px solid ${(props) => buttonColorPicker(props.data_color)};
    filter: none;

    top: 0;
    left: 0;

    /* border-radius: 30px; */
  }
  &:hover {
    background: transparent;
    &:after {
      filter: none;
      animation-play-state: paused;
      animation: unset;
      transform: translateX(0);
    }
  }
`;
