import React, { useState, useEffect } from "react";
import NavbarContainer from "./NavbarContainer";
import MobileNavbarContainer from "./MobileNavbarContainer";
import NavbarLogo from "./NavbarLogo";

import useMediaType from "../../hooks/useMediaType";
import styled from "styled-components";
import NavbarLinks from "./NavbarLinks";
import NavbarButtons from "./NavbarButtons";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

const Navbar = () => {
  const mediaType = useMediaType();
  const [media, setMedia] = useState("desktop");
  const [isOpen, setOpen] = useState(false);
  const [addAnimation, setAddAnimation] = useState(false);
  useEffect(() => {
    isOpen ? disableBodyScroll(document.body) : enableBodyScroll(document.body);
  }, [isOpen]);
  useEffect(() => {
    setMedia(mediaType);
  }, [mediaType]);

  const [scrollY, setScrollY] = useState(0);
  const [smallNavbar, setSmallNavbar] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
      window.scrollY > 100 ? setSmallNavbar(true) : setSmallNavbar(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollY]);
  return (
    <>
      {media === "mobile" || media === "tablet" ? (
        <>
          <MobileNavbarContainer data_small_navbar={smallNavbar} />
        </>
      ) : (
        <NavbarContainer data_small_navbar={smallNavbar}>
          <div className="navbar-desktop-content">
            <NavbarLogo data_small_navbar={smallNavbar} />

            <NavbarLinks data_small_navbar={smallNavbar} />
          </div>
          {/* <NavbarButtons data_small_navbar={smallNavbar} /> */}
        </NavbarContainer>
      )}
    </>
  );
};

export default Navbar;
