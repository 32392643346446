import styled from "styled-components";

const Title = styled.p`
  font-size: 2.810625em;
  color: ${(props) => (props.color === "white" ? "#fff" : "#2a2a2a")};
  display: flex;
  font-weight: 400;
  font-family: Gilroy-ExtraBold;
  letter-spacing: -3px;
  line-height: 0.90224497463836185em;

  @media only screen and (min-width: 615px) {
    font-size: 3.210625em;
    line-height: 1.05224497463836185em;
  }
  @media only screen and (min-width: 768px) {
    font-size: 63px;

    line-height: 1.05224497463836185em;
  }
`;

export default Title;
