import React, { useState, useEffect } from "react";

import { Link } from "gatsby";
import styled from "styled-components";
const NavbarLogoContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 17px;
  z-index: 3;
  left: 23px;
  transition: all 0.2s ease-in-out;
  width: ${(props) => (props.data_small_navbar ? "175px" : "175px")};
  height: ${(props) => (props.data_small_navbar ? "50px" : "50px")};

  margin-bottom: ${(props) => (props.data_small_navbar ? "00px" : "0px")};
  img {
    width: ${(props) => (props.data_small_navbar ? "175px" : "175px")};
    height: ${(props) => (props.data_small_navbar ? "50px" : "50px")};
    filter: contrast(0) brightness(0) invert(1);

    fill: #000;
  }
  &:hover {
    transform: ${(props) =>
      props.data_small_navbar ? "scale(1.1)" : "scale(1.2)"};
  }
  transition: all 0.3s ease-in-out;

  @media only screen and (min-width: 768px) {
    width: 236px;
    height: 35px;
    left: 46px;
    top: ${(props) => (props.data_small_navbar ? "30px" : "35px")};

    img {
      width: 236px;
      height: 35px;
    }
  }
`;

const MobileNavbarLogo = ({ data_small_navbar }) => {
  const [scrollToTop, setScrollToTop] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setScrollToTop(false);
  }, [scrollToTop]);
  return (
    <Link href="/">
      <NavbarLogoContainer
        data_small_navbar={data_small_navbar}
        onClick={() => setScrollToTop(true)}
      >
        <img
          src="/global/Reccly_Logo_RGB.png"
          alt="me"
          width="135"
          height="40"
        />
      </NavbarLogoContainer>
    </Link>
  );
};

export default MobileNavbarLogo;
