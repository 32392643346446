import { useState, useEffect } from "react";
import { screenBreakpoints as sb } from "../../utils/screenBreakpoints";
import useWindowWidth from "./useWindowWidth";

const mediaTypeHandler = (width) => {
  if (width >= sb.desktop) return "desktop";
  if (width >= sb.desktopSmallMin && width <= sb.desktopSmallMax)
    return "desktopSmall";
  if (width >= sb.tabletMin && width <= sb.tabletMax) return "tablet";
  if (width >= 0 && width <= sb.mobileMax) return "mobile";
};

const useMediaType = () => {
  const width = useWindowWidth();
  const [mediaType, setMediaType] = useState(() => mediaTypeHandler(width));

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const handleResize = () =>
        setMediaType(() => mediaTypeHandler(window.innerWidth));
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  });
  return mediaType;
};
export default useMediaType;
