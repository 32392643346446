const Links = [
  {
    title: "STRONA GŁÓWNA",
    links: [
      {
        title: "Studio filmowo - graficzne",
        href: "#"
      },
      {
        title: "Imprezy okolicznościowe",
        href: "#"
      },
      {
        title: "Tworzenie stron i aplikacji",
        href: "#"
      }
    ]
  },
  {
    title: "NARZĘDZIA",
    links: [
      {
        target: "_blank",
        title: "Serwis dla streamerów",
        href: "#"
      },
      {
        title: "Platforma e-commerce",
        href: "#",
        target: "_blank"
      },
      {
        title: "Wyszukiwarka domen",
        href: "#"
      }
    ]
  },
  {
    title: "DOŁĄCZ DO NAS",
    links: [
      {
        title: "Skontaktuj się z nami",
        href: "#"
      },

      {
        title: "Dołącz do nas",
        href: "#"
      },

      {
        title: "Zadaj pytanie",
        href: "#"
      }
    ]
  }
];
export default Links;
