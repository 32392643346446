import React, { useRef, useState, useEffect } from "react";
import Links from "../../../data/navbar_links";
import styled, { keyframes } from "styled-components";
import { CSSTransition } from "react-transition-group";

import MobileNavbarDropdown from "./MobileNavbarDropdown";
import MobileNavbarButtons from "./MobileNavbarButtons";

const Container = styled.div`
  .navbar-mobile-menu-dropdown-visible-enter {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transform: translateX(100%);
    opacity: 0;
    top: -30px;

    width: 100%;
    position: absolute;
    opacity: 0.2;
  }

  .navbar-mobile-menu-dropdown-visible-enter-active {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transform: translateX(0);
    opacity: 0.1;

    transition: all, 0.3s linear;

    top: -30px;

    width: 100%;
    position: absolute;
  }

  .navbar-mobile-menu-dropdown-visible-enter-done {
    display: flex;
    top: -30px;
    opacity: 1;

    width: 100%;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
  }

  .navbar-mobile-menu-dropdown-visible-exit {
    top: -30px;
    transform: translate(0);
    opacity: 0.1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: fixed;

    width: 100%;
  }

  .navbar-mobile-menu-dropdown-visible-exit-active {
    top: -30px;
    width: 100%;
    transform: translateX(100%);
    transition: all 0.3s linear;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: fixed;
    opacity: 0.1;
  }
  .navbar-mobile-menu-links-visible-enter {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transform: translateX(-100%);
    opacity: 0;
    top: -90px;
    padding-top: 90px;
    width: 100%;
    position: absolute;
    opacity: 0.2;
  }

  .navbar-mobile-menu-links-visible-enter-active {
    display: flex;
    flex-direction: column;
    transform: translateY(0);
    opacity: 0.1;
    padding-top: 90px;

    transition: all, 0.3s linear;

    top: -90px;
    width: 100%;
    position: absolute;
  }

  .navbar-mobile-menu-links-visible-enter-done {
    display: flex;
    opacity: 1;
    top: -90px;
    padding-top: 90px;
    width: 100%;
    flex-direction: column;
    position: absolute;
  }

  .navbar-mobile-menu-links-visible-exit {
    transform: translateX(0);
    opacity: 0.1;
    padding-top: 90px;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: -90px;
    width: 100%;
  }

  .navbar-mobile-menu-links-visible-exit-active {
    width: 100%;
    transform: translateX(-100%);
    transition: all 0.3s linear;
    display: flex;
    flex-direction: column;
    padding-top: 90px;
    top: -90px;
    position: fixed;
    opacity: 0.1;
  }
  position: absolute;
  top: 90px;
  left: 0;
  width: 100%;
  padding-top: 90px;

  position: fixed;
  top: calc(50% + 42px);
  transition: all 0.3s linear;
  transform: translateY(-50%)
    ${(props) => (props.isOpen ? "" : "translateX(100%)")};
  z-index: 2;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fffffa;
`;
const NavbarLink = styled.div`
  margin-top: 28px;
  text-align: center;
  font-family: CamptonMedium;
  font-size: 24px;
  color: #2a2a2a;
  a {
    text-decoration: none;
    color: #2a2a2a;
    &:hover {
      color: var(--addon-color);
    }
  }
`;
const MobileNavbarLinks = styled.div`
  position: absolute;
  left: 50%;
  top: 70px;
  transform: translateX(-50%);
  max-width: 308px;
  width: 100%;
`;
const MenuTitle = styled.p`
  cursor: pointer;
  font-family: CamptonMedium;
  text-decoration: none;
  font-size: 24px;
  text-align: center;
  color: var(--addon-color);
`;
const DropdownContainer = styled.div`
  background: #fff;
  padding-top: 93px;
  width: 100%;
  height: 300px;
`;
const MobileNavbarMenu = ({ setOpen, isOpen }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLinks, setShowLinks] = useState(true);
  const [dropdownLinks, setDropdownLinks] = useState([]);
  useEffect(() => {
    Links.map((link) => {
      if (typeof link.dropdown === "object") {
        link.dropdown.map((dropdownLink) => {
          setDropdownLinks((oldArr) => [...oldArr, dropdownLink]);
        });
      }
    });
  }, []);
  return (
    <div>
      <Container isOpen={isOpen}>
        <MobileNavbarLinks>
          <div
            style={
              {
                // height: "100vh", paddingTop: "90px"
              }
            }
          >
            <CSSTransition
              in={showLinks}
              timeout={300}
              classNames="navbar-mobile-menu-links-visible"
              unmountOnExit
            >
              <div
                style={
                  {
                    // height: "100vh"
                  }
                }
              >
                {Links.map((link) => {
                  if (typeof link.dropdown === "object") {
                    return (
                      <NavbarLink
                        onClick={() => {
                          setShowDropdown(!showDropdown);
                          setShowLinks(!showLinks);
                        }}
                      >
                        <a href={link.href}>{link.title}</a>
                      </NavbarLink>
                    );
                  }
                  return (
                    <NavbarLink>
                      <a href={link.href}>{link.title}</a>
                    </NavbarLink>
                  );
                })}
              </div>
            </CSSTransition>
          </div>
          <CSSTransition
            in={showDropdown}
            timeout={300}
            classNames="navbar-mobile-menu-dropdown-visible"
            unmountOnExit
          >
            <DropdownContainer>
              <MenuTitle
                onClick={() => {
                  setShowDropdown(!showDropdown);
                  setShowLinks(!showLinks);
                }}
              >
                WeBrand Systems
              </MenuTitle>
              {dropdownLinks.map((option) => (
                <NavbarLink>
                  <a href={option.href}>{option.title}</a>
                </NavbarLink>
              ))}
            </DropdownContainer>
          </CSSTransition>
        </MobileNavbarLinks>
        <MobileNavbarButtons />
      </Container>
    </div>
  );
};

export default MobileNavbarMenu;
