import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import styled from "styled-components";
const NavbarLogoContainer = styled.div`
  cursor: pointer;

  transition: all 0.2s ease-in-out;
  width: ${(props) => (props.data_small_navbar ? "125px" : "125px")};
  height: ${(props) => (props.data_small_navbar ? "50px" : "50px")};
  margin-right: ${(props) => (props.data_small_navbar ? "110px" : "110px")};
  margin-left: ${(props) => (props.data_small_navbar ? "45px" : "45px")};
  overflow: visible;
  margin-bottom: ${(props) => (props.data_small_navbar ? "00px" : "0px")};
  transform: scale(1);
  img {
    fill: #000;
    filter: contrast(0) brightness(0) invert(1);
  }
  &:hover {
    transform: ${(props) =>
      props.data_small_navbar ? "scale(1.2)" : "scale(1.2)"};
  }
  /* @media only screen and (max-width: 1439px){
        position: absolute;
        left: 50px;
        margin-left: 0;
    } */
`;

const NavbarLogo = ({ data_small_navbar }) => {
  const [scrollToTop, setScrollToTop] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    setScrollToTop(false);
  }, [scrollToTop]);
  return (
    <Link href="/">
      <NavbarLogoContainer
        data_small_navbar={data_small_navbar}
        onClick={() => setScrollToTop(true)}
      >
        <img
          src="/global/Reccly_Logo_RGB.png"
          alt="me"
          width="114"
          height="41"
        />
      </NavbarLogoContainer>
    </Link>
  );
};

export default NavbarLogo;
