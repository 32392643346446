import styled from "styled-components";

const NavbarContainer = styled.div`
  display: flex;
  
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  z-index: 10;
  top: 0;
  box-shadow: ${(props) =>
    props.data_small_navbar ? "0px 3px 15px 5px #69696923" : ""};
  padding-top: ${(props) => (props.data_small_navbar ? "46px" : "45px")};
  padding-bottom: ${(props) => (props.data_small_navbar ? "40px" : "40px")};
  border-top: ${(props) => (props.data_small_navbar ? "4px" : "6px")} solid
    #00e1ff;
  /* border-bottom: 1px solid #eeeeee; */
  background: ${(props) =>
    props.data_small_navbar ? "var(--background-color)" : "transparent"};
  transition: all 0.2s ease-in-out;
  @media only screen and (min-width: 1440px) {
    justify-content: center;
  }
  height: ${(props) => (props.data_small_navbar ? "105px" : "145px")};
  .navbar-desktop-content {
    width: 100%;
    max-width: 1560px;
    padding: 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: translateX(15px);
  }
`;
export default NavbarContainer;
