import styled from "styled-components";

const Text = styled.p`
  padding-left: 2px !important;
  font-size: 16px;
  font-family: CamptonLight;
  letter-spacing: -0.4px;
  color: ${(props) => (props.color === "white" ? "#fff" : "#2a2a2a")};

  @media only screen and (min-width: 768px) {
    padding-left: 2px !important;
    font-size: 17px;
    font-family: CamptonLight;
    letter-spacing: -0.4px;
    color: ${(props) => (props.color === "white" ? "#fff" : "#2a2a2a")};
  }
  @media only screen and (min-width: 1024px) {
    line-height: 27px;
    font-weight: 200;
    font-size: 24px;
  }
`;

export default Text;
