import React, { useState, useContext, useEffect } from "react";
import Head from "next/head";
// import Test from '../Test'
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { theme } from "../../utils/theme";
import Navbar from "../organisms/navbar/Navbar";
import Footer from "../organisms/footer/Footer";
import "react-multi-carousel/lib/styles.css";
import "../../utils/typography.css";
import "../../utils/loading.css";
import smoothscroll from "smoothscroll-polyfill";

// import FullScreenWidth from "./atoms/FullScreenWidth"
const LoadingContext = React.createContext();

const GlobalStyle = createGlobalStyle`
    
    button:focus {outline:0;}
    body {
        width: 100%;
        padding: 0;
        margin: 0;
        color: ${({ theme }) => theme.colors.text};
       
    }

    *, *::before, *::after {
      scroll-behavior: smooth !important;
      --scroll-behavior: smooth !important;
        box-sizing: border-box;
    }
`;

const StyledWrapper = styled.div`
  --background-color: #002360;
  --background-color-dark: #00082a;
  --text-color: #02022f;
  --addon-color: #00e1ff;
  --grey: #b6b6b6;
  --second-button-color: #18b7ff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: var(--background-color);

  min-height: 100vh;
  justify-content: center;
  padding-top: 100px;
  /* padding-top: 200px; */
  align-items: center;
  /* background: ${({ theme }) => theme.colors.backgroundPrimary}; */
  position: relative;
`;
const Layout = ({ children, dark }) => {
  const [darkTheme, setDarkTheme] = useState(false);
  useEffect(() => smoothscroll.polyfill());
  return (
    <LoadingContext.Provider value={420}>
      <Head>
        <title>Reccly.dev</title>
        <link rel="icon" href="/favicon.png" />
      </Head>
      <div id="loading-screen">
        <div className="container">
          <section>
            <div className="loader loader-7">
              <div className="line line1"></div>
              <div className="line line2"></div>
              <div className="line line3"></div>
            </div>
          </section>
        </div>
      </div>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <StyledWrapper>
            <Navbar dark={dark} />
            {children}
            {/* <Test /> */}
            <Footer data_darkTheme={[darkTheme, setDarkTheme]} />
            {/* <Counter /> */}
          </StyledWrapper>
        </>
      </ThemeProvider>
    </LoadingContext.Provider>
  );
};

export default Layout;
