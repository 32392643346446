import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import MobileNavbarMenu from "./MobileNavbarMenu";
import MobileNavbarLogo from "./MobileNavbarLogo";
import { HamburgerSqueeze } from "react-animated-burgers";
import useMediaType from "../../hooks/useMediaType";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

const StyledMobileNavbarContainer = styled.div`
  &::after {
    content: "";
    width: 100%;
    background: var(--addon-color)
    height: 8px;
    position: absolute;
    top: -90px;
    left: 0;
  }
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 20;
  top: 0;
  box-shadow: ${(props) =>
    props.data_small_navbar ? "0px 3px 15px 5px #69696923" : ""};
  padding-top: ${(props) => (props.data_small_navbar ? "46px" : "45px")};
  padding-bottom: ${(props) => (props.data_small_navbar ? "40px" : "40px")};
  margin-top: ${(props) => (props.data_small_navbar ? "8px" : "12px")};
  background: var(--background-color);
  transition: all 0.2s ease-in-out;
  height: ${(props) => (props.data_small_navbar ? "45px" : "55px")};
  &:after {
    transition: all 0.2s ease-in-out;
    content: "";
    position: absolute;
    z-index: 999;
    top: ${(props) => (props.data_small_navbar ? "-8px" : "-12px")};
    width: 100%;
    height: ${(props) => (props.data_small_navbar ? "8px" : "12px")};
    background: var(--addon-color)
  }
  @media only screen and (min-width: 768px) {
    height: ${(props) => (props.data_small_navbar ? "100px" : "110px")};
  }
`;
const HamburgerWrapperMain = styled.div`
  ${(props) =>
    props.data_isOpen
      ? "transform: rotate(180deg) scaleX(1);"
      : "transform: rotate(180deg) scaleX(0.9);"};
  span,
  span::after,
  span::before {
    background: ${(props) => (props.data_isOpen ? "#FE3C53" : "#fff")};
    width: 39px;
    height: 4.5px;
  }
  @media only screen and (min-width: 768px) {
    transition: all 0.3s ease-in-out;
    margin-right: 20px;
    margin-top: ${(props) => (props.data_small_navbar ? "5px" : "10px")};

    ${(props) =>
      props.data_isOpen
        ? "transform: rotate(180deg) scale(1.2) scaleX(1);"
        : "transform: rotate(180deg) scale(1.2) scaleX(0.9);"};
  }
`;
const MobileNavbarContainer = ({ data_small_navbar }) => {
  const mediaType = useMediaType();
  const [isOpen, setOpen] = useState(false);
  useEffect(
    () =>
      isOpen
        ? disableBodyScroll(document.getElementsByTagName("body")[0])
        : clearAllBodyScrollLocks(),
    [isOpen]
  );
  return (
    <StyledMobileNavbarContainer data_small_navbar={data_small_navbar}>
      <MobileNavbarLogo data_small_navbar={data_small_navbar} />
      <HamburgerWrapperMain
        data_small_navbar={data_small_navbar}
        className="HamburgerWrapperMain"
        data_isOpen={isOpen}
      >
        <HamburgerSqueeze
          isActive={isOpen}
          onClick={() => {
            setOpen(!isOpen);
          }}
        />
      </HamburgerWrapperMain>

      <MobileNavbarMenu isOpen={isOpen} setIsOpen={setOpen} />
    </StyledMobileNavbarContainer>
  );
};

export default MobileNavbarContainer;
