import React from "react";
import Links from "../../../data/navbar_links";
import styled from "styled-components";
import NavbarDropdown from "./NavbarDropdown";
import { graphql, useStaticQuery } from "gatsby";

import { Link } from "gatsby";
// import { useRouter } from "next/router";

const options = ["one", "two", "three"];
const defaultOption = options[0];
const NavbarLink = styled.div`
  margin-right: 52px;
  /* margin-right: 18px; */
  margin-top: -10px;
  transition: all 0.2s ease-in-out;
  font-family: CamptonMedium;
  font-size: ${(props) => (props.data_small_navbar ? "16px" : "16px")};
  a {
    text-decoration: none;
    color: #fff;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(2);
      transition: all 0.2s ease-in-out;
      color: #00e1ff;
    }
  }
  @media only screen and (min-width: 1090px) {
    /* margin-right: 36px; */
    margin-right: 52px;
  }
  @media only screen and (min-width: 1240px) {
    margin-right: 52px;
  }
`;
const AllNavbarLinksWrapper = styled.div`
  display: flex;
  margin-right: 150px;
  @media only screen and (min-width: 1280px) {
    margin-right: 100px;
  }
  @media only screen and (min-width: 1440px) {
    margin-right: 0;
  }
`;

const NavbarLinks = ({ data_small_navbar }) => {
  // const router = useRouter();
  const data = useStaticQuery(graphql`
    {
      allMenuLinkContentMenuLinkContent {
        nodes {
          title
          drupal_parent_menu_item
          drupal_id
          link {
            title
            uri
          }
          enabled
          weight
        }
      }
    }
  `);
  const buildLinks = (drupal_data) => {
    const tempLinks = [];
    data.allMenuLinkContentMenuLinkContent.nodes.map((n) => {
      const tempLink = {
        title: n.title,
        href: n.link.uri,
        children: [],
        drupal_id: n.drupal_id,
        disabled: n.enabled,
        new: n.weight === 100,
        parent_drupal_id:
          n.drupal_parent_menu_item !== null
            ? n.drupal_parent_menu_item.split(":")[1]
            : null
      };
      if (tempLink.parent_drupal_id !== null) {
        const tempIndex = tempLinks.findIndex(
          (n) => n.drupal_id === tempLink.parent_drupal_id
        );
        tempLinks[tempIndex].children.push(tempLink);
      } else {
        tempLinks.push(tempLink);
      }
    });
    return tempLinks;
  };
  return (
    <AllNavbarLinksWrapper>
      {buildLinks(data).map((link) => {
        if (link.children.length > 0) {
          console.log(link);
          const options = [];
          link.children.map((dropdownLink) =>
            options.push({
              href: dropdownLink.href,
              title: dropdownLink.title,
              scrollToTop: dropdownLink.title === "HOME" ? true : false,
              new: dropdownLink.new,
              disabled: dropdownLink.disabled
            })
          );
          return (
            <NavbarDropdown
              data_small_navbar={data_small_navbar}
              options={options}
              title={link.title}
              titleHref={link.href}
            />
          );
        }
        return (
          <NavbarLink
            onClick={() =>
              // console.log(router.pathname)
              // router.pathname === "/" ? window.scrollTo(0, 0) : null
              window.scrollTo(0, 0)
            }
            data_small_navbar={data_small_navbar}
          >
            <Link href={link.href}>{link.title}</Link>
          </NavbarLink>
        );
      })}
      {/* <p>{JSON.stringify(data, null, 4)}</p> */}
      {/* <p>{console.log(buildLinks(data))}</p> */}
    </AllNavbarLinksWrapper>
  );
};

export default NavbarLinks;
