import styled from 'styled-components';

const Subtitle = styled.p`
  font-size: 1.031875em;
  letter-spacing: .14em;
  color: ${(props) => (props.color === "white" ? "#fff" : "#2a2a2a")};
  padding-left: 2.5px !important;
  font-family: Gilroy-Light;

  @media only screen and (min-width: 615px){
    margin-bottom: 12px;
    font-size: 30px;;
    letter-spacing: .14em;
    color: ${(props) => (props.color === "white" ? "#fff" : "#2a2a2a")};
    padding-left: 2.5px !important;
    font-family: Gilroy-Light;
  }
  @media only screen and (min-width: 615px) and (max-width: 1023px){
    font-size: 32px;
    letter-spacing: 4px;
  }
`;

export default Subtitle