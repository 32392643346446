import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { pulse } from "react-animations";
import { Link } from "gatsby";
import { osName } from "react-device-detect";
import Card from "../../animations/Card";

const dropdownAnimation = keyframes`${pulse}`;
const Arrow = styled.div`
  /* background-image: url(${(props) =>
    props.data_show ? "/icons/arrow_down.png" : "/icons/arrow_down.png"}); */
  /* transform: ${(props) =>
    !props.data_show ? "rotate(0deg);" : "rotate(180deg)"}; */
  /* filter: ${(props) =>
    !props.data_show ? "contrast(0%) brightness(0%) invert(1);" : "unset;"}; */
  color: ${(props) => (!props.data_show ? "#00e1ff" : "#00e1ff")};
  font-size: 8px;
  position: absolute;
  margin-top: -22px;
  right: 20px;
  /* height: 15px;
  width: 17px; */
  font-family: Raleway-SemiBold;
  transition: ${(props) =>
    props.data_isOSX ? "all 0s linear" : "all 0.2s ease-in-out"};
  /* background-repeat: no-repeat; */
  margin-left: 28px;
  /* margin-top: ${(props) => (props.data_isOSX ? "4px" : "6px")}; */
  ${(props) => (props.data_isHover ? "color: #00e1ff" : "")};
  &:hover {
    color: #00e1ff;
  }
`;
const StyledLink = styled.a`
  transition: all 0.2s ease-in-out;

  font-family: Raleway-SemiBold;
  font-size: 15px;
  text-decoration: none;
  color: ${(props) => (props.disabled ? "#F4F4F4" : "#2a2a2a")};
  margin-top: 20px;
  padding-left: 24px;
  padding-right: 44px;
  &:first-of-type {
    margin-top: 0;
    padding-top: 22px;
  }
  &:last-of-type {
    padding-bottom: 28px;
  }
  &:hover {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    color: #00e1ff;
    ${(props) =>
      props.disabled &&
      `
    // background: red;
    user-select: none;
    cursor: not-allowed;
    position: relative;
    width: 100%;
    // height: 30px;
    color: #F4F4F4;
    user-select: none;
    `}
  }

  &:before {
    display: ${(props) => (props.new ? "flex" : "none")};
    content: "";
    color: ${(props) => (!props.data_show ? "#00e1ff" : "#00e1ff")};
    font-size: 8px;
    position: absolute;
    margin-top: -22px;
    right: 20px;
    font-family: Raleway-SemiBold;
    transition: ${(props) =>
      props.data_isOSX ? "all 0s linear" : "all 0.2s ease-in-out"};
    margin-left: 28px;
    &:hover {
      color: #00e1ff;
    }
  }
`;
const NavbarDropdownContainer = styled.div`
  position: absolute;
  display: flex;
  top: 15px;
  left: -88px;
  height: 100%;
  min-height: 182px;
  width: 230px;
  box-shadow: 0px 6px 18px 0px #2a2a2a15;
  background: #fff;
  animation: 0.5s ${dropdownAnimation};
  z-index: 5;
  flex-direction: column;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  &:before {
    content: "";
    position: absolute;
    top: -2px;
    height: 4px;
    width: calc(100% - 5px);
    left: 50%;
    transform: translateX(-50%);
    background: #00e1ff;
    border-radius: 100%;
  }
`;
const NavbarDropdownButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  margin-right: 18px;
  margin-right: 52px;
  margin-top: -10px;
  p {
    transition: all 0.2s ease-in-out;

    &:hover {
      transition: all 0.2s ease-in-out;

      color: #00e1ff;
    }
    font-family: CamptonMedium;
    text-decoration: none;
    font-size: ${(props) => (props.data_small_navbar ? "16px" : "16px")};
    color: ${(props) => (props.data_show ? "#00e1ff" : "#fff")};
  }
  div:first-of-type {
    /* transform: ${(props) =>
      props.data_show ? "rotate(180deg)" : "rotate(0deg)"}; */
    top: 7px;
    left: 6px;
    width: 14px !important;
    height: 10px !important;
  }
  @media only screen and (min-width: 1090px) {
    margin-right: 36px;
    margin-right: 52px;
  }
  @media only screen and (min-width: 1240px) {
    margin-right: 52px;
  }
`;
const NavbarDropdownWrapper = styled.div`
  position: relative;
  &:hover {
    p {
      color: #00e1ff;
    }
  }
`;

const NavbarDropdown = ({ data_small_navbar, title, titleHref, options }) => {
  const [show, setShow] = useState(false);
  const [isHover, setHover] = useState(false);
  // useEffect(() => {
  //   console.log(isHover);
  // }, [isHover]);
  const [isOSX, setIsOSX] = useState(false);
  useEffect(() => setIsOSX(osName === "Mac OS"), []);
  return (
    <NavbarDropdownWrapper
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <NavbarDropdownButtonContainer
        data_small_navbar={data_small_navbar}
        data_show={show}
      >
        <p onClick={() => setShow(!show)}>{title}</p>
        <Arrow
          data_isOSX={isOSX}
          data_isHover={isHover}
          className="navbar--dropdown--arrow"
          data_show={show}
          onClick={() => setShow(!show)}
        >
          NEW!
        </Arrow>
      </NavbarDropdownButtonContainer>
      <Card>
        {show === true && (
          <NavbarDropdownContainer>
            {options.map((option) =>
              option.disabled ? (
                <StyledLink disabled={option.disabled} new={option.new}>
                  {console.log(option)}
                  {option.title}
                </StyledLink>
              ) : (
                <Link href={option.href}>
                  <StyledLink disabled={option.disabled} new={option.new}>
                    {console.log(option)}
                    {option.title}
                  </StyledLink>
                </Link>
              )
            )}
          </NavbarDropdownContainer>
        )}
      </Card>
    </NavbarDropdownWrapper>
  );
};

export default NavbarDropdown;
