import React from "react";
import styled from "styled-components";

const NavbarButtonsContainer = styled.div`
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 4;
  margin-top: 0px;
  padding: 0 24px;
`;
const NavbarButton = styled.a`
  padding: ${(props) =>
    props.data_small_navbar ? "00px 50px" : "0px 50px"} !important;
  border: 1px solid #e9e9e9;
  color: #818181 !important;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  font-family: CamptonMedium;
  height: ${(props) => (props.data_small_navbar ? "45px" : "45px")};
  text-align: center;
  align-items: center;
  justify-content: center;
  max-width: 203px !important;
  background: #fff;
  display: flex;
  width: 100%;
  text-align: center;
  margin-top: 25px;
  border-radius: 30px;
  font-size: ${(props) =>
    props.data_small_navbar ? "16px" : "16px"} !important;
  &:hover {
    color: #fff;
    background: var(--addon-color)
    border: 1px solid var(--addon-color)
    box-shadow: 0px 9px 23px -8px rgba(0, 137, 255, 0.6);
    transform: scale(1.05);
  }
`;

const NavbarButtonBlue = styled.a`
  display: flex;
  padding: ${(props) =>
    props.data_small_navbar ? "00px 32px" : "0px 32px"} !important;
  max-width: 203px;
  width: 100%;
  transition: all 0.2s ease-in-out;
  color: white !important;
  font-size: ${(props) =>
    props.data_small_navbar ? "16px" : "16px"} !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: var(--addon-color)
  text-decoration: none;
  font-family: CamptonMedium;
  border-radius: 30px;
  height: ${(props) => (props.data_small_navbar ? "45px" : "45px")};
  text-align: center;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 9px 23px -8px rgba(0, 137, 255, 0.6);
  }
`;

const MobileNavbarButtons = ({ data_small_navbar }) => {
  return (
    <NavbarButtonsContainer>
      <NavbarButtonBlue
        target="_blank"
        rel="noreferrer"
        href="https://system.webrand.pl/"
      >
        System
      </NavbarButtonBlue>
      {/* <NavbarButton  href="#">Rejestracja</NavbarButton> */}
    </NavbarButtonsContainer>
  );
};

export default MobileNavbarButtons;
