import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";

import { CSSTransition } from "react-transition-group";

import MobileNavbarButtons from "./MobileNavbarButtons";

const NavbarDropdownContainer = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  padding-top: 205px;
  width: 100%;
  height: 110vh;
  background: #fff;
  z-index: 5;
  text-align: center;
  flex-direction: column;
  margin-top: -385px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 21vh;
  width: 100vw;
  a {
    font-family: Raleway-SemiBold;
    font-size: 32px;
    color: #2a2a2a;
    text-decoration: none;
    text-align: center;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 32px;
    &:first-of-type {
      margin-top: 0;
      padding-top: 22px;
    }
    &:last-of-type {
      padding-bottom: 28px;
    }
    &:hover {
      color: #0f0f0f;
    }
  }
`;
const NavbarDropdownButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    &:hover {
      color: var(--addon-color);
    }
    font-family: CamptonMedium;
    text-decoration: none;
    font-size: 24px;
    color: #2a2a2a;
  }
  div:first-of-type {
    transform: ${(props) =>
      props.data_show ? "rotate(180deg)" : "rotate(0deg)"};
    top: 17.5px;
    filter: brightness(0) invert(1);
    left: 10px;
    width: 20px !important;
    height: 15px !important;
    transform: rotate(270deg);
  }
`;

const MenuTitle = styled.p`
  cursor: pointer;
  font-family: CamptonMedium;
  text-decoration: none;
  font-size: 24px;
  color: var(--addon-color);
`;
const ButtonsWrapper = styled.div`
  position: absolute;
  bottom: -360px;
  left: 0;
  width: 100%;
  height: 300px;
  z-index: 10;
`;
const NavbarDropdownWrapper = styled.div`
  position: relative;
`;

const MobileNavbarDropdown = ({ data_small_navbar, title, options }) => {
  const [show, setShow] = useState(false);
  return (
    <NavbarDropdownWrapper>
      <NavbarDropdownButtonContainer
        data_small_navbar={data_small_navbar}
        data_show={show}
      >
        <p onClick={() => setShow(!show)}>{title}</p>
        <img
          src="/arrows/arrow-down-dropdown.png"
          onClick={() => setShow(!show)}
          height={20}
          width={20}
        />
      </NavbarDropdownButtonContainer>
      {/* <CSSTransition
                in={show}
                timeout={300}
                classNames="navbar-mobile-menu-dropdown-visible"
                unmountOnExit
              > */}
      <CSSTransition in={show} timeout={300} classNames="visible" unmountOnExit>
        <NavbarDropdownContainer>
          <MenuTitle
            onClick={() => {
              setShow(!show);
            }}
          >
            WeBrand Systems
          </MenuTitle>
          {options.map((option) => (
            <a href={option.href}>{option.title}</a>
          ))}
        </NavbarDropdownContainer>
        <ButtonsWrapper>
          <MobileNavbarButtons />
        </ButtonsWrapper>
        {/* </CSSTransition> */}
      </CSSTransition>
    </NavbarDropdownWrapper>
  );
};

export default MobileNavbarDropdown;
