import React, { useEffect } from "react";

// import router, { useRouter } from "next/router";
import { Link } from "gatsby";
import styled from "styled-components";

import Links from "../../../data/footer_links";

import Text from "../../atoms/Text";
import Title from "../../atoms/Title";
import Button from "../../atoms/Button";

const Container = styled.div`
  background: var(--background-color-dark);
  width: 100%;
`;
const Wrapper = styled.div`
  width: 100%;
  max-width: 1560px;
  margin: 0 auto;
`;
const LogoWrapper = styled.div`
  display: flex;
  padding-top: 75px;
  padding-bottom: 75px;
  overflow: visible;
  justify-content: center;
  div {
    transition: all 0.2s ease-in-out;
    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }
`;
const Logo = styled.img`
  filter: brightness(0) invert(1);
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;
const SocialsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 355px;
`;
const SocialsButtonsWrapper = styled.div`
  display: flex;
  padding-bottom: 27px;
  justify-content: space-between;
  max-width: 245px;
  width: 100%;
  margin: 0 auto;
`;

const LinksWrapper = styled.div`
  padding: 0 20px;
`;

const StyledTitle = styled(Title)`
  font-size: 21px;
  padding-bottom: 27px;
  color: #fff;
  letter-spacing: -0.4px;

  text-transform: uppercase;
`;
const StyledButton = styled(Button)`
  background: var(--second-button-color);
  justify-content: flex-end;
  border: 1px solid var(--second-button-color);
  color: #fff;
  padding-left: 70px;
  &:after {
    position: absolute;
    content: "";
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
  }
  &:hover {
    background: var(--second-button-color);
  }
`;
const LinkFooterLink = styled(Text)`
  padding-bottom: 27px;
  font-family: CamptonBook;
  color: var(--grey);

  &:hover {
    color: var(--addon-color);
    cursor: pointer;
  }
`;

const SocialButton = styled.div`
  transition: all 0.2s ease-in-out;
  background: #fff;
  width: 41px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  &:hover {
    cursor: pointer;
    /* background: var(--addon-color); */
    box-shadow: 0px 0px 23px 5px #00e1ff40;
    transform: scale(1.2);
  }
`;

const CopyrightsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 75px;
  padding-bottom: 90px;
`;
const Copyrights = styled(Text)`
  padding-bottom: 15px;
  color: var(--grey);
  font-family: CamptonBook;
`;
const RightsReserver = styled(Text)`
  color: var(--grey);
  font-family: CamptonBook;
`;

const Footer = () => {
  // const router = useRouter();
  useEffect(() => {
    let fonts = [];
    fonts.push(
      new FontFace("CascadiaCode", "url(/fonts/CascadiaCode.ttf)", {
        style: "normal",
        weight: "400"
      })
    );
    fonts.push(
      new FontFace("CascadiaCodePL", "url(/fonts/CascadiaCodePL.ttf)", {
        style: "normal",
        weight: "400"
      })
    );
    fonts.push(
      new FontFace("Poppins-ExtraBold", "url(/fonts/Poppins-ExtraBold.ttf)", {
        style: "normal",
        weight: "400"
      })
    );
    fonts.push(
      new FontFace("Gilroy-Medium", "url(/fonts/Gilroy-Medium.ttf)", {
        style: "normal",
        weight: "400"
      })
    );
    fonts.push(
      new FontFace("Gilroy-Light", "url(/fonts/Gilroy-Light.otf)", {
        style: "normal",
        weight: "400"
      })
    );
    fonts.push(
      new FontFace("Gilroy-ExtraBold", "url(/fonts/Gilroy-ExtraBold.otf)", {
        style: "normal",
        weight: "400"
      })
    );
    fonts.push(
      new FontFace("CamptonLight", "url(/fonts/CamptonLight.otf)", {
        style: "normal",
        weight: "400"
      })
    );
    fonts.push(
      new FontFace("CamptonMedium", "url(/fonts/CamptonMedium.otf)", {
        style: "normal",
        weight: "400"
      })
    );
    fonts.push(
      new FontFace("CamptonExtraBold", "url(/fonts/CamptonExtraBold.otf)", {
        style: "normal",
        weight: "400"
      })
    );
    fonts.push(
      new FontFace("CamptonBold", "url(/fonts/CamptonBold.otf)", {
        style: "normal",
        weight: "400"
      })
    );
    fonts.push(
      new FontFace("CamptonBook", "url(/fonts/CamptonBook.otf)", {
        style: "normal",
        weight: "400"
      })
    );
    fonts.push(
      new FontFace("CamptonSemiBold", "url(/fonts/CamptonSemiBold.otf)", {
        style: "normal",
        weight: "400"
      })
    );
    fonts.push(
      new FontFace("Raleway-Medium", "url(/fonts/Raleway-Medium.ttf)", {
        style: "normal",
        weight: "400"
      })
    );
    fonts.push(
      new FontFace("Raleway-SemiBold", "url(/fonts/Raleway-SemiBold.ttf)", {
        style: "normal",
        weight: "400"
      })
    );
    for (var i in fonts) {
      document.fonts.add(fonts[i]);
      fonts[i].load();
    }
    document.fonts.ready.then(function (e) {
      setTimeout(() => {
        document.getElementById("loading-screen").style.display = "none";
      }, 1000);
      // When all fonts are loaded, wait 1second and disable loading screen
    });
  });

  return (
    <Container>
      <Wrapper>
        <LogoWrapper>
          {/* <Link href={location?.pathname === "/" ? "#" : "/"}> */}
          <Logo src="/global/logo-reccly.svg" width={140} height={50} />
          {/* </Link> */}
        </LogoWrapper>
        <ContentWrapper>
          <SocialsWrapper>
            <StyledTitle>Społeczności</StyledTitle>
            <SocialsButtonsWrapper>
              <Link href={"#"}>
                <SocialButton>
                  <img src="/icons/facebook.svg" width={25} height={18} />
                </SocialButton>
              </Link>
              <Link href={"#"}>
                <SocialButton>
                  <img src="/icons/facebook.svg" width={25} height={18} />
                </SocialButton>
              </Link>
              <Link href={"#"}>
                <SocialButton>
                  <img src="/icons/facebook.svg" width={25} height={18} />
                </SocialButton>
              </Link>
              <Link href={"#"}>
                <SocialButton>
                  <img src="/icons/facebook.svg" width={25} height={18} />
                </SocialButton>
              </Link>
            </SocialsButtonsWrapper>
            <StyledButton>WIADOMOŚĆ MESSENGER</StyledButton>
          </SocialsWrapper>
          {Links.map((linkGroup) => (
            <LinksWrapper>
              <StyledTitle>{linkGroup.title}</StyledTitle>
              {linkGroup.links.map((l) => (
                <Link href={l.href}>
                  <LinkFooterLink>{l.title}</LinkFooterLink>
                </Link>
              ))}
            </LinksWrapper>
          ))}
        </ContentWrapper>
        <CopyrightsWrapper>
          <Copyrights>© reccly {new Date().getFullYear()}</Copyrights>
          <RightsReserver>ALL RIGHTS RESERVED</RightsReserver>
        </CopyrightsWrapper>
      </Wrapper>
    </Container>
  );
};

export default Footer;
