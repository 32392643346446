export const colors = {
  disabled: "#AFAAAD",
  white: "#FFFFFF",
  backgroundPrimary: "#FFFFFF",
  backgroundSecondary: "#F4F4F4",
  backgroundGradientColors: {
    first: "#15C5CF",
    second: "#F4F4F4",
  },
  button: {
    primary: "#15C5CF",
    secondary: "#2E80E0",
    tertiary: "#5C00FF",
    hover: {
      primary: "#0F8E95",
      secondary: "#205497",
      tertiary: "#3A02A1",
      white: "#205497",
    },
  },
  site: {
    primaryLight: "#5C00FF",
    primaryDark: "#1E0E5E",
    secondaryLight: "#15C5CF",
    secondaryDark: "#2E80E0",
    hover: {
      primaryLight: "#5003DF",
      secondaryLight: "#16B3C4",
      secondaryDark: "#2C75D3",
    },
    active: {
      primaryLight: "#5C00FF",
      secondaryLight: "#15C5CF",
      secondaryDark: "#2E80E0",
    },
  },
  textPrimaryColor: "#1E0E5E",
}
