import React, { useRef, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useControls } from "leva";

import styled from "styled-components";

const AnimatedDiv = styled(animated.div)`
  border-radius: 20px;
  transition: all 0.5s ease-out;
  &:hover {
    /* background: red; */
    box-shadow: 0px 100px 50px #00000015;
  }
`;

const calc = (x, y) => [
  -(y - window.innerHeight / 4) / 902,
  // 0,
  -(x - window.innerWidth / 2.5) / 20,
  1.04,
];

const trans = (x, y, s) =>
  `perspective(400px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const initialConfig = {
  mass: 0,
  tension: 170,
  friction: 6,
  clamp: false,
  precision: 0.01,
  velocity: 0,
  easing: (t) => t,
};

const Card = ({ children, springConfig = initialConfig }) => {
  const ref = useRef(null);
  const [xys, set] = useState([0, 0, 1]);
  // const config = useControls(initialConfig);
  const props = useSpring({
    xys,
    config: {
      mass: 20,
      tension: 70,
      friction: 26,
      clamp: false,
      precision: 1,
      velocity: 0,
      easing: (t) => t,
    },
  });
  return (
    <div className="ccard-main" ref={ref}>
      <AnimatedDiv
        className="ccard"
        style={{ transform: props.xys.to(trans) }}
        onMouseLeave={() => set([0, 0, 1])}
        onMouseMove={(e) => {
          const rect = ref.current.getBoundingClientRect();
          set(calc(e.clientX, e.clientY, rect));
        }}
        {...props}
      >
        {children}
      </AnimatedDiv>
    </div>
  );
};

export default Card;
