import React from "react";
import styled from "styled-components";

const NavbarButtonsContainer = styled.div`
  display: flex;
  margin-top: ${(props) => (props.data_small_navbar ? "-13px" : "-5px")};
  right: 30px;
  position: absolute;

  @media only screen and (min-width: 1440px) {
    right: 0;
    position: absolute;
    right: 50px;
    margin-left: 75px;
    padding-right: 0;
  }
  @media only screen and (min-width: 1500px) {
    margin-left: 130px;
    right: unset;
    left: calc(50% + 510px);
    transform: translateX(-50%);
  }
  @media only screen and (min-width: 1530px) {
    left: calc(50% + 520px);
  }
  @media only screen and (min-width: 1540px) {
    left: calc(50% + 528px);
  }
`;
const NavbarButton = styled.a`
  border: 1px solid #e9e9e9;
  color: #818181;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  font-family: CamptonMedium;
  padding: 0 20px;
  height: ${(props) => (props.data_small_navbar ? "45px" : "45px")};
  padding-top: ${(props) => (props.data_small_navbar ? "12.5px" : "12.5px")};
  border-radius: 30px;
  font-size: ${(props) => (props.data_small_navbar ? "16px" : "16px")};
  &:hover {
    color: #fff;
    background: var(--addon-color)
    border: 1px solid var(--addon-color)
    box-shadow: 0px 9px 23px -8px rgba(0, 137, 255, 0.6);
    transform: scale(1.05);
  }
  @media only screen and (min-width: 1150px) {
    padding: 0 30px;
    padding-top: ${(props) => (props.data_small_navbar ? "12.5px" : "12.5px")};
  }
  @media only screen and (min-width: 1440px) {
    padding: ${(props) => (props.data_small_navbar ? "00px 50px" : "0px 50px")};
    padding-top: ${(props) => (props.data_small_navbar ? "12.5px" : "12.5px")};
  }
`;

const NavbarButtonBlue = styled.a`
  padding: 0 20px;

  transition: all 0.2s ease-in-out;
  color: white;
  font-size: ${(props) => (props.data_small_navbar ? "16px" : "16px")};
  padding-top: ${(props) => (props.data_small_navbar ? "12.5px" : "12.5px")};

  background: var(--addon-color)
  text-decoration: none;
  font-family: CamptonMedium;
  border-radius: 30px;
  height: ${(props) => (props.data_small_navbar ? "45px" : "45px")};
  margin-left: 16px;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 9px 23px -8px rgba(0, 137, 255, 0.6);
  }
  @media only screen and (min-width: 1150px) {
    padding: 0 30px;
    padding-top: ${(props) => (props.data_small_navbar ? "12.5px" : "12.5px")};
  }
  @media only screen and (min-width: 1440px) {
    margin-left: 32px;
    padding: ${(props) => (props.data_small_navbar ? "00px 50px" : "0px 50px")};
    padding-top: ${(props) => (props.data_small_navbar ? "12.5px" : "12.5px")};
  }
`;

const NavbarButtons = ({ data_small_navbar }) => {
  return (
    <NavbarButtonsContainer data_small_navbar={data_small_navbar}>
      {/* <NavbarButton data_small_navbar={data_small_navbar} href="#">Rejestracja</NavbarButton> */}
      <NavbarButtonBlue
        data_small_navbar={data_small_navbar}
        target="_blank"
        rel="noreferrer"
        href="https://system.webrand.pl/"
      >
        Panel logowania
      </NavbarButtonBlue>
    </NavbarButtonsContainer>
  );
};

export default NavbarButtons;
