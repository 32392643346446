const Links = [
  {
    title: "HOME",
    href: "#",
    dropdown: [
      {
        title: "Reccly Developers",
        href: "#",
        new: true,
      },
      {
        title: "Reccly Weedings",
        href: "#",
      },
      {
        title: "Reccly Photography",
        href: "#",
        disabled: true,
      },
      {
        title: "Reccly Kamera Akcja!",
        href: "#",
        disabled: true,
      },
    ],
  },
  {
    title: "OFERTA",
    href: "/",
  },
  {
    title: "WSPÓŁPRACA",
    href: "/",
  },
  {
    title: "PORTFOLIO",
    href: "/",
  },
  {
    title: "O NAS",
    href: "/",
  },
  {
    title: "APLIKUJ",
    href: "/",
  },
  {
    title: "ROZRYWKA",
    href: "/",
  },
  {
    title: "KONTAKT",
    href: "/kontakt",
  },
];
export default Links;
